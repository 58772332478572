import React from 'react';
import './Offers.css';
import { Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Offers = () => {
  const { t, i18n } = useTranslation(); // Utiliser le hook useTranslation pour obtenir la fonction t et la langue actuelle
  
  // Obtenir la langue actuelle
  const currentLang = i18n.language || 'en';

  return (
    <div className='offers'>
      <div className="offers-left">
        <h1>{t('feelYourVST')}</h1>
        <Link to={`/${currentLang}/about`} style={{ textDecoration: 'none' }}>
          <button>{t('discoverMore')}</button>
        </Link>
      </div>
      <div className="offers-right">
        <h2>{t('subtitle1')}</h2>
        <div className="offers-feat">
        {/*<p><b>3</b> {t('midimap')}</p>
          <p><b>1</b> {t('jackport')}</p>
          <p><b>6</b> {t('analogKnob')}</p>
          <p><b>4</b> {t('ledFootswitch')}</p>
          <p><b>{t('compatible')}</b></p>*/}
<p>{t('compatible')}</p>
        </div>
        <div className='offers-right-second'>

          {/*<p><b>{t('compatible')}</b></p>*/}
        </div>
      </div>
    </div>
  );
}

export default Offers;
