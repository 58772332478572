import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Card, CardContent, Typography, Rating, Link } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import commentsData from '../Assets/comments.json'; // Assurez-vous d'avoir un fichier JSON avec les commentaires
import './CommentCarousel.css';

const CommentCarousel = ({ productId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const productComments = commentsData.filter(comment => comment.productId === 1);

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % productComments.length);
  }, [productComments.length]);

  const commentRefs = useRef([]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 15000); // 20 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [handleNext]);

  useEffect(() => {
    commentRefs.current.forEach((ref, index) => {
      if (ref && ref.scrollHeight > ref.clientHeight) {
        if (ref.nextElementSibling) {
          ref.nextElementSibling.style.display = 'block';
        }
      } else {
        if (ref && ref.nextElementSibling) {
          ref.nextElementSibling.style.display = 'none';
        }
      }
    });
  }, [currentIndex]);

  return (
    <Box id="customer-reviews" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
      <div className="CommentCarousel-display">
        <AnimatePresence mode="wait">
          {productComments.slice(currentIndex, currentIndex + 4).map((comment, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
              className="CommentCarousel-card"
            >
              <Card elevation={3} sx={{ p: 1, textAlign: 'center', borderRadius: 2 }}>
                <CardContent sx={{ height: '250px' }}>
                  <Typography variant="body2" sx={{ fontSize: '12px', color: '#333' }}>
                    {comment.firstName} {comment.lastName}
                  </Typography>
                  <Rating value={comment.rating} readOnly precision={0.5} sx={{ my: 1 }} /><br/>
                  <Typography variant="body1" component={Link} href={comment.trustpilotUrl} target="_blank" sx={{ fontSize: '16px', fontWeight: 'bold', color: '#0b2946' }}>
                    {comment.title}
                  </Typography>
                  <Typography variant="body1" className="content comment-review" ref={el => commentRefs.current[index] = el} sx={{ fontSize: '12px', color: '#0b2946' }}>
                    {comment.review}
                  </Typography>
                  <div className="ellipsis">...</div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </Box>
  );
};

export default CommentCarousel;