import React from 'react';
import './DescriptionBox.css';
import { useTranslation } from 'react-i18next';
import foot2 from '../Assets/allfoot01.jpg'
import pot01 from '../Assets/box_white01.jpg'
import whiteled from '../Assets/white_light1.jpg'
import redled from '../Assets/white_light2.jpg'
import footled from '../Assets/footled_blue1.jpg'
import pickholder from '../Assets/pickercus01.jpg'
import levampepickerg from '../Assets/levampepickerg.jpg'
import pickholder2 from '../Assets/pickerdecors.jpg'
import apt from '../Assets/amplitube_mini.png'
import neural1 from '../Assets/neuraldsp_mini.png'
import fatblob from '../Assets/fatblob_mini.png'

import mlsound from '../Assets/mlsoundlab_mini.png'
import ableton from '../Assets/ableton-logo_mini.png'
import cubase from '../Assets/cubase-logo_mini.png'

import garage from '../Assets/garageband-logo_mini.png'
import fl from '../Assets/fl-studio-logo_mini.png'
import reaper from '../Assets/reaper-logo_mini.png'

import reason from '../Assets/reason-logo_mini.png'

import CommentCarousel from '../CommentCarousel/CommentCarousel';
import ztopper from '../Assets/zoomtopper.jpg'
import levampefoot from '../Assets/levampefoot.jpg'

const DescriptionBox = (props) => {
  const { t } = useTranslation();
  const { product } = props;
  return (
    <div className='descriptionbox'>

      <div className="descriptionbox-description">


      {product.idstore <= 4 && (
  <>
        <h2>{t('clientSay')}</h2>
              <hr/>
<CommentCarousel productId={product.idstore} />
<h2>{t('descriptionBox.compatiblevst')}</h2>
        <div className="descriptionbox-logo"><img src={apt} alt="amplitube"/><img src={neural1} alt="neuraldsp"/><img src={fatblob} alt="fatblob"/><img src={mlsound} alt="ml sounds lab"/><img src={ableton} alt="ableton"/><img src={cubase} alt="cubase"/>
        <img src={garage} alt="garage"/>
        <img src={reaper} alt="reaper"/>
        <img src={fl} alt="fl"/>
        <img src={reason} alt="reason"/>
        </div>
        <p>{t('descriptionBox.compatiblevstdesc')}</p>
        <hr/>
        <h2>{t('descriptionBox.analogPotentiometers')}</h2>
        <p>{t('descriptionBox.analogPotentiometersDesc')}</p>

        <img src={pot01} alt="levampe" className='descriptionbox-imgpot'/>
        
        <h2>{t('descriptionBox.midiChannelSelector')}</h2>
        <p>{t('descriptionBox.midiChannelSelectorDesc')}</p>
        <p>{t('descriptionBox.midiChannelSelectorDesc2')}</p>
        <p>{t('descriptionBox.midiChannelSelectorDesc3')}</p>
        <p>{t('descriptionBox.midiChannelSelectorDesc4')}</p>
        
        <div className="descriptionBox-carrouss">
        <img src={whiteled} alt="LeVampe with led" className='descriptionbox-imgled'/><img src={redled} alt="" className='descriptionbox-imgled'/>
        </div>
        
        <p>{t('descriptionBox.midiChannelSelectorDesc5')}</p>
        <h2>{t('descriptionBox.jackport')}</h2>
        <p>{t('descriptionBox.jackportdesc')}</p>
        <img src={levampefoot} alt="pédialier levampe" className='descriptionbox-imgped'/>
        <h2>{t('descriptionBox.footswitch')}</h2>
        <hr/>
        <img src={foot2} alt="" className='descriptionbox-imgped'/>
        <h2>{t('descriptionBox.switchesWithLed')}</h2>
        <p>{t('descriptionBox.switchesWithLedDesc')}</p>
        <p>{t('descriptionBox.switchesWithLedDesc2')}</p>
        <img src={footled} alt="pédialier le vampe" className='descriptionbox-imgped'/>
        <h2>{t('descriptionBox.compatibility')}</h2>
        <p>{t('descriptionBox.cablesIncluded')}</p>

        <hr/>
        <iframe className='youtube-video' src="https://www.youtube.com/embed/aqWecap-i2I?si=pHjEcHNCM3Lcq6Hx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <h2>{t('descriptionBox.neural')}</h2>
        <iframe className='youtube-video' src="https://www.youtube.com/embed/videoseries?si=flrocxQuKQUb9swc&amp;list=PLPeTR8WWcsYMOrLw6jZPc1bLkH2hy64zM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <h2>{t('descriptionBox.amplitube')}</h2>
        <iframe className='youtube-video' src="https://www.youtube.com/embed/videoseries?si=ASe77mAHhShRMlJa&amp;list=PLPeTR8WWcsYMeNbHHsluCGRkRe_hth-ex" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <h2>{t('descriptionBox.fatblob')}</h2>
        <iframe className='youtube-video' src="https://www.youtube.com/embed/videoseries?si=EgJQheiB_9z-kyQm&amp;list=PLPeTR8WWcsYO-zXY65UxwSrD-oAD7O4s5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        </>
)}
      {(product.idstore === 5 ) && (
  <>
          <h2>{t('descriptionBoxPicker.title')}</h2>
          <p>{t('descriptionBoxPicker.description')}</p>
          <img src={levampepickerg} alt="porte mediator" className='descriptionbox-imgped'/>
          <img src={pickholder2} alt="porte médiator" className='descriptionbox-imgped'/>
  </>
)}


{(product.idstore === 6 ) && (
  <>
          <h2>{t('descriptionBoxPicker.titlecustom')}</h2>
          <p>{t('descriptionBoxPicker.descriptioncustom')}</p>
          <img src={pickholder} alt="porte mediator" className='descriptionbox-imgped'/>
          <img src={pickholder2} alt="porte médiator" className='descriptionbox-imgped'/>
  </>
)}

{(product.idstore === 7 ) && (
  <>
          <h2>{t('descriptionBoxTopper.titlecustom')}</h2>
          <p>{t('descriptionBoxTopper.descriptioncustom')}</p>
          <img src={ztopper} alt="toppers" className='descriptionbox-imgped'/>
  </>
)}
      </div>
    </div>
  );
};

export default DescriptionBox;
