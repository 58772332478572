import React, { useEffect } from 'react';
import './Footer.css';
import fb_icon from '../Assets/facebook.png';
import yt_icon from '../Assets/youtbe.png';
import ins_icon from '../Assets/instagram.png';
import amppng256 from '../Assets/amp2.png';
import stripe from '../Assets/stripe.png';
import pp from '../Assets/pp.png';
import garanty from '../Assets/satisfaction.png';
import colissimo from '../Assets/colissimo.png';
import logomr from '../Assets/logomr.png';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || 'en';
  const location = useLocation(); // Hook pour obtenir l'URL actuelle

  // Scroll en haut lorsque l'URL change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]); // Déclenchement à chaque changement de route


  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;
    document.body.appendChild(script);
  
    script.onload = () => {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(document.getElementsByClassName('trustpilot-widget')[0], true);
      }
    };
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  return (
    <div className='footer'>
      <div className="footer-logo">
        
        <p>{t('footer.shipEurope')}</p>
      </div>
      <div><img src={amppng256} alt="AMP Logo" /></div>
      <div className="footer-copyright">
        <hr />
        <p><b>LeVampe™</b> - {t('footer.copyright')}</p>
      </div>
      <div className="footer-copyright2">



      <div className="footer-flex">
        <div className="legal">
        <img src={stripe} alt="Stripe" /><img src={pp} alt="Stripe" /><br/>
        {t('securepayment')}
        </div>
        <div className="footer-cpr">
          <p><a href="https://www.facebook.com/levampe" target='_blank' rel="noopener noreferrer"><img src={fb_icon} alt="Facebook" /></a>
          <a href="https://www.youtube.com/@CustomControlz" target='_blank' rel="noopener noreferrer"><img src={yt_icon} alt="Youtube" /></a>
          <a href="https://www.instagram.com/cuscontrolz/" target='_blank' rel="noopener noreferrer"><img src={ins_icon} alt="Instagram" /></a>
          </p>
          <Link to={`/${currentLang}/mentions`}>{t('legalmention')}</Link> - 
          <Link to={`/${currentLang}/cgv`}>{t('cgvfoot')}</Link> - 
          <Link to={`/${currentLang}/contact`}>Contact</Link>
          
       </div>
       </div>


        <div className="footer-flex">
          <div className="legal">
          <img src={colissimo} alt="colissimo" /><br/>
          <img src={logomr} alt="colissimo" /><br/>
          {t('shippingcol')}
          </div>
          
          <div className="footer-cpr">
            <p>{t('footer.neuralDSP')}</p>
            <p>{t('footer.fatblob')}</p>
            <p>{t('footer.amplitube')}</p>
            <p>{t('footer.levampetm')} <a href="https://www.customcontrolz.com" target='_blank' rel="noopener noreferrer">CustomControlZ</a></p>
        </div>
       </div>



       <div className="seo"><img src={garanty} alt="satisfaction garantie" /><br/>{t('satisfaction')}<br/></div>
       

       <div class="trustpilot-widget" data-locale="fr-FR" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="675de57fca7451749389c5f0" data-style-height="52px" data-style-width="100%">
  <a href="https://fr.trustpilot.com/review/levampe.com" target="_blank" rel="noreferrer">Trustpilot</a>
</div>

       <div className="seo">{t('footer.seo')}</div>
    </div>
    
 
 </div>
  );
}

export default Footer;
