import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'; // Correct imports
import './ContactDisplay.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha(); // Hook for reCAPTCHA
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      console.error('Execute reCAPTCHA not yet available');
      setError(true);
      return;
    }

    try {
      // Obtenir le token reCAPTCHA avant d'envoyer les données
      const captchaToken = await executeRecaptcha('submit');
      
      const response = await fetch(`${backendUrl}/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, captchaToken }), // Inclure le token reCAPTCHA
      });

      if (response.ok) {
        setSuccess(true);
        setError(false);
        setFormData({ name: '', email: '', message: '' }); // Réinitialiser le formulaire
      } else {
        setSuccess(false);
        setError(true);
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setError(true);
      setSuccess(false);
    }
  };

  const { t } = useTranslation();

  return (
    <div className="contactdisplay-main">
      <Box className="contact-form" component="form" onSubmit={handleSubmit} sx={{ maxWidth: 500, mx: 'auto' }}>
        <Typography variant="h4">
        {t('contactus')}
        </Typography>
        <TextField
          fullWidth
          label={t('firstname')}
          name="name"
          value={formData.name}
          onChange={handleChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label={t('yourmail')}
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Message"
          name="message"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          {t('send')}
        </Button>
        {success && <Typography color="success" sx={{ mt: 2 }}>{t('sendsuccess')}</Typography>}
        {error && <Typography color="error" sx={{ mt: 2 }}>{t('sendfail')}</Typography>}
      </Box>

      <div className='other' dangerouslySetInnerHTML={{ __html: t('othercontact') }} />
    </div>
  );
};

const ContactDisplay = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}> {/* Utilise ta clé de site ici */}
    <ContactForm />
  </GoogleReCaptchaProvider>
);

export default ContactDisplay;
