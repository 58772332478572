import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'; 
//import ReactPixel from 'react-facebook-pixel';
import Collection from '../Components/Collection/Collection'
import Offers from '../Components/Offers/Offers'
import Footer from '../Components/Footer/Footer'

const Shop = () => {
  const { t} = useTranslation(); // Utilise le hook pour accéder aux traductions
  /*
  const advancedMatching = { };
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  
  ReactPixel.init('1415265855733995', advancedMatching, options);
  ReactPixel.pageView(); // For tracking page view*/

  return (
    <div>
  <Helmet>
  <title>{t('seo.shop_title')}</title>
  <meta name="description" content={t('seo.shop_description')} />
  <meta name="keywords" content={t('seo.shop_keywords')} />
  <link rel="canonical" href="https://www.levampe.com" />
  <link rel="alternate" hreflang="x-default" href="https://www.levampe.com/en" />
  <link rel="alternate" hreflang="fr" href="https://www.levampe.com/fr" />
          {/* Intégration de Meta Pixel */}
          <script>
          {`
            !function(f,b,e,v,n,t,s) {
              if(f.fbq) return; n=f.fbq=function() { n.callMethod ?
              n.callMethod.apply(n, arguments) : n.queue.push(arguments); };
              if(!f._fbq) f._fbq=n;
              n.push=n; n.loaded=!0; n.version='2.0';
              n.queue=[]; t=b.createElement(e); t.async=!0;
              t.src=v; s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s);
            }(window, document, 'script',
              'https://connect.facebook.net/en_US/fbevents.js');

            fbq('init', '1415265855733995'); 
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
            <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1415265855733995&ev=PageView&noscript=1"/>
          `}
        </noscript>
        </Helmet>
        <Offers></Offers>
        <Collection></Collection>
        <Footer></Footer>
    </div>
  )
}

export default Shop
/*<Hero></Hero>*/

/*        
        */